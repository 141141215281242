// src/services/SessionService.ts
import { log } from "utils/logger";

/**
 * Service to handle user session based on activity.
 */
class SessionService {
  idleTimeout: number;
  lastActivityTimeStamp: number;
  logoutCallback: () => void;
  checkActivityIntervalId: number | null = null;

  /**
   * Initializes the SessionService with a logout function.
   * @param logoutFunction - Function to call when the user is logged out due to inactivity.
   */
  constructor(logoutFunction: () => void) {
    this.idleTimeout = 60 * 60 * 1000;
    this.lastActivityTimeStamp = new Date().getTime();
    this.logoutCallback = logoutFunction;
    log.debug("SessionService initialized.");
  }

  /**
   * Resets the activity timer.
   */
  resetTimer = (): void => {
    this.lastActivityTimeStamp = new Date().getTime();
    log.debug("Timer reset.");
  };

  /**
   * Checks if the user has been idle for longer than the idleTimeout.
   * If so, it logs out the user.
   */
  checkActivity = (): void => {
    const now = new Date().getTime();
    if (now - this.lastActivityTimeStamp > this.idleTimeout) {
      log.info("Idle timeout exceeded. Logging out user.");
      this.logoutCallback();
    }
  };

  /**
   * Starts monitoring user activity and sets up event listeners and interval checks.
   */
  startMonitoring = (): void => {
    const eventListeners: string[] = ["click", "load", "scroll"];
    eventListeners.forEach((event) =>
      window.addEventListener(event, this.resetTimer)
    );
    log.debug("Starting user activity monitoring.");

    this.checkActivityIntervalId = window.setInterval(this.checkActivity, 1000);
    log.debug(
      "Activity check interval started with ID:",
      this.checkActivityIntervalId
    );
  };

  /**
   * Stops monitoring user activity and removes event listeners and interval checks.
   */
  stopMonitoring = (): void => {
    const eventListeners: string[] = ["click", "load", "scroll"];
    eventListeners.forEach((event) =>
      window.removeEventListener(event, this.resetTimer)
    );
    log.debug("Stopping user activity monitoring.");

    if (this.checkActivityIntervalId !== null) {
      window.clearInterval(this.checkActivityIntervalId);
      log.debug(
        "Activity check interval stopped with ID:",
        this.checkActivityIntervalId
      );
      this.checkActivityIntervalId = null;
    }
  };
}

export default SessionService;
