import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";
import appsIcon from "./assets/icons/apps-2.png";
import wpLogo from "features/user/assets/wp_logo.png";
import { useAuth } from "features/auth/hooks/useAuth";

const menuItems = [
  {
    path: "/user/apps",
    label: "App Library",
    icon: (
      <img src={appsIcon} alt="Apps Icon" style={{ width: 32, height: 32 }} />
    ),
  },
];

const DesktopMenu: React.FC<{ menuOpen: boolean; toggleMenu: () => void }> = ({
  menuOpen,
  toggleMenu,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const [selectedIndex, setSelectedIndex] = useState<string>("/user/apps");

  useEffect(() => {
    const path = location.pathname;
    setSelectedIndex(path);
  }, [location.pathname]);

  const handleListItemClick = (path: string) => {
    setSelectedIndex(path);
    navigate(path);
  };

  const iconAndTextStyles = { color: "#202020" };
  const selectedStyles = { color: "#202020", fontWeight: "bold" };

  const getListItemStyles = (path: string) =>
    selectedIndex === path ? selectedStyles : iconAndTextStyles;

  const renderMenuItem = (item: any) => (
    <ListItem
      button
      key={item.path}
      selected={selectedIndex === item.path}
      onClick={() => handleListItemClick(item.path)}
      sx={{
        padding: menuOpen ? "8px 16px" : "8px 0",
        justifyContent: menuOpen ? "flex-start" : "center",
        backgroundColor:
          selectedIndex === item.path
            ? "rgba(255, 255, 255, 0.2)"
            : "transparent",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: menuOpen ? 40 : "auto",
          justifyContent: "center",
          ...getListItemStyles(item.path),
        }}
      >
        {item.icon}
      </ListItemIcon>
      {menuOpen && (
        <ListItemText
          primary={
            selectedIndex === item.path ? (
              <strong>{item.label}</strong>
            ) : (
              item.label
            )
          }
          sx={{
            transition: "opacity 0.3s",
            pl: 1,
            color: "#202020",
            whiteSpace: "nowrap",
          }}
        />
      )}
    </ListItem>
  );

  return (
    <Box
      sx={{
        width: menuOpen ? 250 : 60,
        maxWidth: menuOpen ? 250 : 60,
        bgcolor: "#f6f6f6",
        position: "fixed",
        height: "100vh",
        overflow: "auto",
        transition: "width 0.3s",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRight: "1px solid #d3d3d3",
        zIndex: 1300,
      }}
    >
      <IconButton
        onClick={toggleMenu}
        sx={{
          m: 1,
          alignSelf: menuOpen ? "flex-end" : "center",
          color: "#202020",
        }}
      >
        {menuOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
      <Box
        sx={{
          pb: 4,
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={wpLogo}
          alt="Logo"
          style={{
            maxWidth: "80%",
            cursor: "pointer",
          }}
          onClick={() => navigate("/user/apps")}
        />
      </Box>
      <List component="nav" aria-label="main navigation" sx={{ width: "100%" }}>
        {menuItems.map(renderMenuItem)}
      </List>
      <List component="nav" sx={{ mt: "auto", width: "100%" }}>
        <ListItem
          button
          selected={selectedIndex === "logout"}
          onClick={() => {
            setSelectedIndex("logout");
            logout();
          }}
          sx={{
            padding: menuOpen ? "8px 16px" : "8px 0",
            justifyContent: menuOpen ? "flex-start" : "center",
            backgroundColor:
              selectedIndex === "logout"
                ? "rgba(255, 255, 255, 0.2)"
                : "transparent",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: menuOpen ? 40 : "auto",
              justifyContent: "center",
              ...getListItemStyles("logout"),
            }}
          >
            <ExitToAppIcon />
          </ListItemIcon>
          {menuOpen && (
            <ListItemText
              primary={
                selectedIndex === "logout" ? <strong>Logout</strong> : "Logout"
              }
              sx={{
                transition: "opacity 0.3s",
                pl: 1,
                color: "#202020",
                whiteSpace: "nowrap",
              }}
            />
          )}
        </ListItem>
      </List>
    </Box>
  );
};

export default DesktopMenu;
