// src/AppRoutes.tsx
import { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
} from "react-router-dom";
import LoadingIndicator from "components/LoadingIndicator";
import PrivateRoute from "features/auth/components/guards/PrivateRoute";
import Dashboard from "features/user/Dashboard";

// Öffentliche Seiten
const LoginPage = lazy(() => import("features/auth/pages/LoginPage"));
const TermsOfUsePage = lazy(() => import("views/TermsOfUsePage"));
const PrivacyPolicyPage = lazy(() => import("views/PrivacyPolicyPage"));

// User Seiten und Komponenten
const AppsOverviewPage = lazy(
  () => import("features/user/pages/AppsOverviewPage")
);
const CVTesterPage = lazy(() => import("features/user/pages/CVTesterPage"));
const IGTPage = lazy(() => import("features/user/pages/IGTPage"));
const PCTPage = lazy(() => import("features/user/pages/PCTPage"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <LoginPage />, index: true },
      { path: "login", element: <LoginPage /> },
      { path: "privacy-policy", element: <PrivacyPolicyPage /> },
      { path: "terms-of-use", element: <TermsOfUsePage /> },
    ],
  },
  {
    path: "/",
    element: <PrivateRoute adminRequired={false} />,
    children: [
      {
        path: "user",
        element: <Navigate replace to="/user/apps" />,
      },
      {
        path: "user/apps",
        element: (
          <Dashboard>
            <AppsOverviewPage />
          </Dashboard>
        ),
      },
      {
        path: "user/apps/cv-tester",
        element: (
          <Dashboard>
            <CVTesterPage />
          </Dashboard>
        ),
      },
      {
        path: "user/apps/igt",
        element: (
          <Dashboard>
            <IGTPage />
          </Dashboard>
        ),
      },
      {
        path: "user/apps/pct",
        element: (
          <Dashboard>
            <PCTPage />
          </Dashboard>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/login" replace />,
  },
]);

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default function AppRoutes() {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
