// src/hooks/useAuth.ts
import { useContext } from "react";
import { AuthContext } from "features/auth/context/AuthContext";
import { log } from "utils/logger";

/**
 * Custom hook to access the AuthContext.
 *
 * @returns {object} The current auth context value.
 * @throws Will throw an error if used outside of an AuthProvider.
 */
export const useAuth = () => {
  log.debug("useAuth hook called.");

  const context = useContext(AuthContext);

  if (context === undefined) {
    log.error("useAuth must be used within an AuthProvider.");
    throw new Error("useAuth must be used within an AuthProvider");
  }

  log.debug("useAuth returning context:", context);
  return context;
};
