// PrivateRoute.tsx
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "features/auth/hooks/useAuth";
import { log } from "utils/logger";
import LoadingIndicator from "components/LoadingIndicator";

/**
 * Props für die PrivateRoute-Komponente.
 */
interface PrivateRouteProps {
  /**
   * Gibt an, ob Admin-Rechte erforderlich sind, um die Route zu betreten.
   */
  adminRequired?: boolean;
}

/**
 * PrivateRoute-Komponente, die den Zugriff auf Routen basierend auf Authentifizierung und Autorisierung regelt.
 *
 * @param {PrivateRouteProps} props - Die Eigenschaften für die PrivateRoute.
 * @returns {JSX.Element} - Die gerenderte Komponente.
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({
  adminRequired = false,
}) => {
  const { user, isAdmin, isLoading } = useAuth();

  // Debug-Log für den Zugriff auf die Private Route
  log.debug("Private Route Zugriff:", {
    adminRequired,
    isAdmin,
    user: user?.email,
    isLoading,
  });

  // Anzeigen eines Ladeindikators, solange die Authentifizierung geladen wird
  if (isLoading) {
    log.info("Ladezustand aktiv, Anzeige des Ladeindikators.");
    return <LoadingIndicator />;
  }

  // Weiterleiten zum Login, wenn kein Benutzer eingeloggt ist
  if (!user) {
    log.info("Weiterleitung zum Login, da kein Benutzer eingeloggt ist.");
    return <Navigate to="/login" replace />;
  }

  // Weiterleiten zum Login, wenn Admin-Rechte erforderlich sind und der Benutzer kein Admin ist
  if (adminRequired && !isAdmin) {
    log.warn("Weiterleitung zu /login, da keine Admin-Rechte vorhanden sind.");
    return <Navigate to="/login" replace />;
  }

  // Rendern der geschützten Route
  log.info("Zugriff auf die geschützte Route gewährt.");
  return <Outlet />;
};

export default PrivateRoute;
