// src/components/SessionTimeoutHandler.tsx

import React, { useEffect } from "react";
import { useAuth } from "features/auth/hooks/useAuth";
import SessionService from "features/auth/services/SessionService";
import { log } from "utils/logger";

/**
 * SessionTimeoutHandler Component
 * Handles session timeout monitoring and user logout on session expiration.
 */
const SessionTimeoutHandler: React.FC = () => {
  const { logout } = useAuth();

  useEffect(() => {
    log.info("SessionTimeoutHandler mounted");
    log.debug("Initializing SessionService with logout function");

    // Initialize SessionService with the logout function
    const sessionService = new SessionService(logout);

    // Start monitoring session timeout
    sessionService.startMonitoring();
    log.info("Started session monitoring");

    // Cleanup function to stop monitoring on component unmount
    return () => {
      log.info("SessionTimeoutHandler unmounted");
      sessionService.stopMonitoring();
      log.info("Stopped session monitoring");
    };
  }, [logout]);

  return null;
};

export default SessionTimeoutHandler;
