import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
} from "@mui/material";
import { ExitToApp as ExitToAppIcon } from "@mui/icons-material";
import appsIcon from "./assets/icons/apps-2.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "features/auth/hooks/useAuth";
import mobileLogo from "features/user/assets/wp_logo.png";

const menuItems = [
  {
    path: "/user/apps",
    label: "App Library",
    icon: (
      <img src={appsIcon} alt="Apps Icon" style={{ width: 32, height: 32 }} />
    ),
  },
];

const iconAndTextStyles = { color: "#555" };
const selectedStyles = { color: "#000", fontWeight: "bold" };

const CustomMenuIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="5" width="18" height="2" rx="1" fill="currentColor" />
    <rect x="3" y="11" width="12" height="2" rx="1" fill="currentColor" />
    <rect x="3" y="17" width="18" height="2" rx="1" fill="currentColor" />
  </svg>
);

const MobileMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [pathToNavigate, setPathToNavigate] = useState("");

  useEffect(() => {
    if (!isOpen && pathToNavigate) {
      if (
        pathToNavigate === "/user/cards/details" &&
        location.pathname === "/user/cards/details"
      ) {
        window.location.reload();
      } else {
        navigate(pathToNavigate);
      }
      setPathToNavigate("");
    }
  }, [isOpen, pathToNavigate, navigate, location.pathname]);

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (path: string) => {
    setPathToNavigate(path);
    setIsOpen(false);
  };

  const getListItemStyles = (path: string) =>
    pathToNavigate === path ? selectedStyles : iconAndTextStyles;

  const renderMenuItem = (item: any) => (
    <ListItem
      button
      key={item.path}
      onClick={() => handleMenuClick(item.path)}
      sx={getListItemStyles(item.path)}
    >
      <ListItemIcon sx={getListItemStyles(item.path)}>{item.icon}</ListItemIcon>
      <ListItemText primary={item.label} />
    </ListItem>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: "#f6f6f6",
          boxShadow: "none",
          height: 60,
          backgroundColor: "#f6f6f6",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            minHeight: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #E0E0E0",
            backgroundColor: "#f6f6f6",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              ml: 1,
              mr: 1,
            }}
          >
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{
                color: "#222222",
              }}
            >
              <CustomMenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor="left"
        open={isOpen}
        onClose={handleDrawerToggle}
        onOpen={handleDrawerToggle}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
      >
        <div
          style={{
            width: 250,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            component="img"
            sx={{ maxWidth: 60, padding: 2, pb: 4 }}
            alt="Logo"
            src={mobileLogo}
          />
          <List>{menuItems.map(renderMenuItem)}</List>
          <Box sx={{ height: 16 }} />
          <List style={{ marginTop: "auto" }}>
            <ListItem
              button
              key="logout"
              onClick={() => {
                handleMenuClick("logout");
                logout();
              }}
              sx={getListItemStyles("logout")}
            >
              <ListItemIcon sx={getListItemStyles("logout")}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Abmelden" />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenu;
