// AuthContext.tsx

import React, { createContext, useState, useEffect } from "react";
import { authService } from "features/auth/services/authService";
import { AuthContextState } from "features/auth/types/auth";
import { log } from "utils/logger";

// Create the AuthContext with a default undefined value
const AuthContext = createContext<AuthContextState | undefined>(undefined);

/**
 * AuthProvider component
 * Provides authentication state and methods to its children via AuthContext
 */
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<AuthContextState["user"]>(null);
  const [isAdmin, setIsAdmin] = useState<AuthContextState["isAdmin"]>(false);
  const [isLoading, setIsLoading] =
    useState<AuthContextState["isLoading"]>(true);
  const [error, setError] = useState<AuthContextState["error"]>(null);

  useEffect(() => {
    log.debug("Setting up auth state monitoring...");

    // Subscribe to auth state changes
    const unsubscribe = authService.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const tokenResult = await user.getIdTokenResult();
          setIsAdmin(!!tokenResult.claims.admin);
          setUser(user);
          setIsLoading(false);
          log.info(
            `User logged in: ${user.email} | Is Admin: ${!!tokenResult.claims
              .admin}`
          );
        } catch (error) {
          log.error("Error fetching ID token result:", error);
          setError(error instanceof Error ? error : new Error(String(error)));
          setIsLoading(false);
        }
      } else {
        setUser(null);
        setIsAdmin(false);
        setIsLoading(false);
        log.info("No user logged in.");
      }
    });

    // Cleanup subscription on component unmount
    return () => {
      log.debug("Stopping auth state monitoring.");
      unsubscribe();
    };
  }, []);

  /**
   * Logs out the current user
   */
  const logout = async () => {
    setIsLoading(true);
    log.debug("Logging out user...");
    try {
      await authService.logout();
      setUser(null);
      setIsAdmin(false);
      setIsLoading(false);
      setError(null);
      log.info("User logged out successfully.");
    } catch (error) {
      log.error("Error logging out:", error);
      setError(error instanceof Error ? error : new Error(String(error)));
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ user, isAdmin, isLoading, error, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
