// App.tsx
import React from "react";
import AppRoutes from "routes/AppRoutes";
import { ThemeProvider } from "@mui/material/styles";

// Theme und globale Styles
import theme from "styles/theme";
import "./App.css";

// Authentifizierung und Session-Timeout
import { AuthProvider } from "features/auth/context/AuthContext";
import SessionTimeoutHandler from "features/auth/components/guards/SessionTimeoutHandler";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SessionTimeoutHandler />
        <AppRoutes />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
