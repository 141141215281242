// src/utils/logger.ts
type LogLevelStrings = "debug" | "info" | "warn" | "error" | "none";
type LogLevels = {
  [level in LogLevelStrings]: number;
};

const logLevels: LogLevels = {
  debug: 3,
  info: 2,
  warn: 1,
  error: 0,
  none: -1,
};

console.log("[LOGGING] Current logging level:", process.env.REACT_APP_LOGGING);

const currentLogLevel: number =
  logLevels[process.env.REACT_APP_LOGGING as LogLevelStrings] ?? logLevels.none;

export const log = {
  debug: (message: string, ...optionalParams: any[]): void => {
    if (currentLogLevel >= logLevels.debug) {
      console.log(`[DEBUG] ${message}`, ...optionalParams);
    }
  },
  info: (message: string, ...optionalParams: any[]): void => {
    if (currentLogLevel >= logLevels.info) {
      console.info(`[INFO] ${message}`, ...optionalParams);
    }
  },
  warn: (message: string, ...optionalParams: any[]): void => {
    if (currentLogLevel >= logLevels.warn) {
      console.warn(`[WARN] ${message}`, ...optionalParams);
    }
  },
  error: (message: string, ...optionalParams: any[]): void => {
    if (currentLogLevel >= logLevels.error) {
      console.error(`[ERROR] ${message}`, ...optionalParams);
    }
  },
};
