import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", sans-serif',
    fontWeightRegular: 400,
    fontWeightBold: 700,
    h1: { fontFamily: '"Roboto", sans-serif' },
    h2: { fontFamily: '"Roboto", sans-serif' },
    h3: { fontFamily: '"Roboto", sans-serif' },
    h4: { fontFamily: '"Roboto", sans-serif' },
    h5: { fontFamily: '"Roboto", sans-serif' },
    h6: { fontFamily: '"Roboto", sans-serif' },
    body1: { fontFamily: '"Roboto", sans-serif' },
    body2: { fontFamily: '"Roboto", sans-serif' },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: { fontFamily: '"Roboto", sans-serif' },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: { fontFamily: '"Roboto", sans-serif' },
        primary: { fontFamily: '"Roboto", sans-serif' },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { borderRadius: 8 },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: { borderRadius: 16 },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        containedPrimary: {
          backgroundColor: "#38b6ff",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#0094eb",
          },
        },
        containedSecondary: {
          backgroundColor: "#000000",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#323232",
          },
        },
      },
    },
  },
});

export default theme;
