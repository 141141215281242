import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";

const LoadingIndicator: React.FC = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor={isSmallScreen ? "#f7f7f7" : "transparent"}
    >
      <CircularProgress sx={{ color: isSmallScreen ? "inherit" : "inherit" }} />
    </Box>
  );
};

export default LoadingIndicator;
