import React, { useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import DesktopMenu from "features/user/DesktopMenu";
import MobileMenu from "features/user/MobileMenu";

const Dashboard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    if (isMediumScreen) {
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
  }, [isMediumScreen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#ffffff",
        minHeight: "100vh",
        pt: 0,
      }}
    >
      {isSmallScreen ? (
        <MobileMenu />
      ) : (
        <DesktopMenu menuOpen={menuOpen} toggleMenu={toggleMenu} />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: menuOpen ? `calc(100% - 250px)` : `calc(100% - 60px)` },
          ml: { sm: menuOpen ? `250px` : `60px` },
          transition: "width 0.3s, margin-left 0.3s",
          p: 2,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Dashboard;
