// authService.ts

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  onAuthStateChanged,
  User,
  UserCredential,
} from "firebase/auth";
import { getFirestore, doc, setDoc, Timestamp } from "firebase/firestore";
import { app } from "firebaseConfig";
import { log } from "utils/logger";

const db = getFirestore(app);

export const authService = {
  /**
   * Sends a password reset email to the provided email address.
   * @param email - The email address to send the password reset email to.
   */
  async sendResetEmail(email: string): Promise<void> {
    try {
      log.debug("Attempting to send password reset email to:", email);
      await sendPasswordResetEmail(getAuth(app), email);
      log.info(`Password reset email sent to: ${email}`);
    } catch (error) {
      log.error(`Error sending password reset email to ${email}:`, error);
      throw error;
    }
  },

  /**
   * Registers a new user with email and password and sends a verification email.
   * @param email - The email address of the new user.
   * @param password - The password for the new user.
   * @returns The registered user.
   */
  async register(email: string, password: string): Promise<User> {
    try {
      log.debug("Attempting to register user with email:", email);
      const userCredential: UserCredential =
        await createUserWithEmailAndPassword(getAuth(app), email, password);
      await sendEmailVerification(userCredential.user);
      log.info(`New user registered and verification email sent to: ${email}`);
      return userCredential.user;
    } catch (error) {
      log.error(`Error registering ${email}:`, error);
      throw error;
    }
  },

  /**
   * Creates a document in the "users" collection for the new user.
   * @param userId - The ID of the user.
   * @param acceptedTerms - Whether the user accepted the terms.
   */
  async createUserDocument(
    userId: string,
    acceptedTerms: boolean
  ): Promise<void> {
    try {
      log.debug("Attempting to create user document for userId:", userId);
      await setDoc(doc(db, "users", userId), {
        acceptedTerms,
        termsAcceptedAt: Timestamp.now().toDate().toISOString(),
      });
      log.info(`User document created for: ${userId}`);
    } catch (error) {
      log.error(`Error creating user document for ${userId}:`, error);
      throw error;
    }
  },

  /**
   * Logs in the user with email and password.
   * @param email - The email address of the user.
   * @param password - The password of the user.
   */
  async login(email: string, password: string): Promise<void> {
    try {
      log.debug("Attempting to log in user with email:", email);
      await signInWithEmailAndPassword(getAuth(app), email, password);
      log.info(`User logged in: ${email}`);
    } catch (error) {
      log.error(`Error logging in ${email}:`, error);
      throw error;
    }
  },

  /**
   * Logs out the current user.
   */
  async logout(): Promise<void> {
    try {
      log.debug("Attempting to log out current user.");
      await signOut(getAuth(app));
      log.info("User logged out");
    } catch (error) {
      log.error("Error logging out:", error);
      throw error;
    }
  },

  /**
   * Subscribes to authentication state changes.
   * @param callback - The callback to execute on auth state change.
   * @returns A function to unsubscribe from the auth state listener.
   */
  onAuthStateChanged(callback: (user: User | null) => void) {
    log.debug("Setting up auth state change listener.");
    const unsubscribe = onAuthStateChanged(getAuth(app), (user) => {
      log.debug("Authentication state changed:", user);
      callback(user);
    });
    return unsubscribe;
  },
};
